<template>
	<v-card class="mx-auto my-1" rounded="xl">
		<v-card-text>
			<template v-if="!editing">
				<p style="word-break: break-all; white-space: pre-line">
					{{ comment.content }}
				</p>
			</template>
			<template v-else>
				<v-form>
					<v-textarea
						v-model="commentContent"
						:label="$t('newsfeed.content')"
						:placeholder="$t('newsfeed.whatAreYouThinking')"
						:rows="1"
						auto-grow
						outlined
					/>
					<v-btn color="primary" @click="callEditComment()">
						{{ $t('settings.edit') }}
					</v-btn>
				</v-form>
			</template>
		</v-card-text>

		<v-card-actions class="caption">
			<v-row>
				<v-col cols="12" md="6">
					<span>{{
						$t('newsfeed.postedBy', {
							user: comment.author.fullname,
							timedelta: humanTime,
						})
					}}</span>
				</v-col>

				<v-col cols="12" md="6">
					<v-btn small text icon @click="callAproveComment(true)">
						<v-icon small>
							{{ aproved ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
						</v-icon>
					</v-btn>
					<v-badge class="mr-2" :content="thumbsUp ? thumbsUp : '0'" :value="thumbsUp ? thumbsUp : '0'" inline overlap />

					<v-btn small text icon @click="callAproveComment(false)">
						<v-icon small>
							{{ disaproved ? 'mdi-thumb-down' : 'mdi-thumb-down-outline' }}
						</v-icon>
					</v-btn>
					<v-badge :content="thumbsDown ? thumbsDown : '0'" :value="thumbsDown ? thumbsDown : '0'" inline overlap />

					<v-btn v-if="comment.author.bool" text small class="ml-6" @click="editMode()">
						<v-icon left small> mdi-pencil-outline </v-icon>
						{{ $t('settings.edit') }}
					</v-btn>

					<v-btn v-if="comment.author.bool || post.author.bool" color="red" text small class="ml-2" @click="remove">
						<v-icon left small> mdi-delete-forever </v-icon>
						{{ $t('settings.delete') }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'FeedComment',
	props: {
		post: {
			type: Object,
			required: true,
		},
		comment: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			editing: false,
			commentContent: this.comment.content,
			aproved: this.comment.aproved,
			disaproved: this.comment.disaproved,
			thumbsUp: this.comment.thumbsUp,
			thumbsDown: this.comment.thumbsDown,
		}
	},
	computed: {
		humanTime() {
			return this.$moment(this.comment.timestamp).fromNow()
		},
		...mapGetters({
			currentUser: 'user/currentUser',
			setupInfo: 'user/setupInfo',
		}),
	},
	methods: {
		editMode() {
			this.editing = !this.editing
		},
		callEditComment() {
			this.editComment({
				post: this.post,
				comment: this.comment,
				content: this.commentContent,
			}).then(({ success, comment }) => {
				if (success) {
					this.editMode()
					this.commentContent = comment.content
					this.comment.content = comment.content
				}
			})
		},
		remove() {
			this.$emit('remove', this.comment)
		},
		callAproveComment(toBeAproved) {
			if ((this.aproved && toBeAproved) || (this.disaproved && !toBeAproved)) {
				this.undoAproveComment({ comment: this.comment }).then(({ success }) => {
					if (success) {
						this.aproved = false
						this.disaproved = false
						if (toBeAproved) {
							this.thumbsUp -= 1
						} else {
							this.thumbsDown -= 1
						}
					}
				})
			} else if (this.setupInfo.verified.value) {
				this.aproveComment({ aproved: toBeAproved, comment: this.comment }).then(({ success }) => {
					if (success) {
						if (toBeAproved) {
							if (this.disaproved) {
								this.thumbsDown -= 1
							}
							this.aproved = true
							this.disaproved = false
							this.thumbsUp += 1
						} else {
							if (this.aproved) {
								this.thumbsUp -= 1
							}
							this.aproved = false
							this.disaproved = true
							this.thumbsDown += 1
						}
					}
				})
			} else {
				console.log('needs logged in user')
			}
		},
		...mapActions('feed', ['editComment', 'aproveComment', 'undoAproveComment']),
	},
}
</script>
